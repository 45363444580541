/* manypage.com - this file automatically generated at 19-02-05 19:48:17 */

.-mp-layer { position: absolute; left: 0; top: 0; width: 100%; height: 0; display: block; background: transparent; overflow: visible; }
#__select__ { z-index: 1111101000; }
#__panel__ { z-index: 1111101100; position: fixed; }
#__float__ { z-index: 1111101200; }
#__menu__ { z-index: 1111101300; }
#__help__ { z-index: 1111101400; }
#__wait__ { z-index: 1111101500; }
.-mp-lay-tx {} .-mp-lay-tx > * { float: none }
.-mp-lay-fl {} .-mp-lay-fl > * { float: left } .-mp-lay-fl:after{ clear: both; display: table; content: ' '; }
.-mp-lay-fr { position: relative; } .-mp-lay-fr > * { position: absolute}
.-mp-mask-outer {position: relative;display: inline-block;}
.-mp-mask-inner {width: 100%;height: 100%;position: absolute;left: 0;top: 0;}

body{background-color:#e6e6e6;font-family:Open-Sans, sans-serif;margin:0px;min-height:100%;}
.-mp-dropmenu-bar{}
.-mp-dropmenu{position:relative;}
.-mp-dropmenu-head{border-color:transparent;border-style:solid;border-width:0px;box-sizing:border-box;color:#ffffff;font-size:12px;font-weight:normal;height:32px;line-height:32px;min-height:16px;min-width:20px;padding:0px 12px 0px 12px;}
.OPEN>.-mp-dropmenu-head{background-color:#a5bfde;border-color:#a5bfde;}
.-mp-dropmenu-body{background-color:#ffffff;border-color:#808892;border-radius:0px 0px 4px 4px;border-style:solid;border-width:1px;box-shadow:0 6px 12px rgba(0, 0, 0, 0.3);clear:both;color:#414c58;display:none;margin:0px;min-height:30px;overflow-x:hidden;overflow-y:hidden;padding:0px 0px 5px 0px;position:absolute;top:32px;z-index:1300;}
.EDIT.-mp-dropmenu-body{display:block;position:absolute;top:-260px;}
.OPEN>.-mp-dropmenu-body{display:block;}
.-mp-dropmenu-item{background-color:#ffffff;border-color:#eeeeee;border-style:solid;border-width:0px;clear:both;position:relative;width:100%;}
.SELECTED.-mp-dropmenu-item{color:#666666;}
.-mp-dropmenu-divider{border-color:#b3c4cc;border-style:solid;border-width:0px 0px 1px 0px;clear:both;height:0px;margin:5px 0px 5px 0px;width:100%;}
.-mp-dropmenu-item:hover{background-color:#a5bfde;color:#ffffff;}
.DISABLED.-mp-dropmenu-item:hover{background-color:inherit;color:inherit;}
.-mp-dropmenu-item-text{font-size:14px;height:32px;line-height:32px;margin:3px 0px 3px 22px;min-width:30px;}
.-mp-dropmenu-item-text--icon{margin:3px 0px 3px 38px;}
.-mp-dropmenu-item-icon{height:28px;margin:5px 9px 5px 9px;position:absolute;width:22px;}
.-hx-popmenu{cursor:pointer;height:32px;position:relative;}
.-hx-popmenu-head{background-color:#ffffff;border-color:#414c58;border-radius:5px;border-style:solid;border-width:1px;box-sizing:border-box;color:#414c58;height:100%;line-height:32px;min-height:32px;min-width:50px;overflow-x:hidden;overflow-y:hidden;padding:0px;width:100%;}
.-mp-feint-head{color:#a7a7a7;font-style:italic;}
.-mp-feint-body{opacity:0.500;filter:alpha(opacity=50);}
.-hx-popmenu-contents{box-sizing:border-box;height:auto;min-height:100%;min-width:30px;padding:0px 12px 0px 0px;width:100%;}
.-hx-popmenu-body{background-color:#ffffff;border-color:#808892;border-radius:5px;border-style:solid;border-width:1px;box-shadow:0 6px 12px rgba(0, 0, 0, 0.3);box-sizing:border-box;clear:both;color:#414c58;cursor:pointer;display:none;min-height:30px;overflow-x:hidden;overflow-y:hidden;padding:0px;position:absolute;top:1px;z-index:1000;}
.OPEN>.-hx-popmenu-body{display:block;}

.-hx-popmenu-not-body {background-color: lightskyblue;bottom: 0;display: none;left: 0;opacity: 0.5;position: fixed;right: 0;top: 0;z-index: 999;}
.OPEN>.-hx-popmenu-not-body{display:block;}

.-hx-popmenu-item{clear:both;white-space:nowrap;width:100%;}
.-hx-popmenu-arrow-enc{border-color:#cccccc;border-radius:0px 4px 4px 0px;border-style:solid;border-width:0px;height:30px;position:absolute;right:1px;width:22px;}
.-hx-popmenu-arrow{background-image:url(https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/chrome/chevron-down-oslo.svg?Signature=bQrtVRCuvrDIsaupX6bgv%2FM%2B4RA%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA);background-position:center center;background-repeat:no-repeat;background-size:30px 12px;height:100%;width:100%;}
.-hx-popmenu-item:hover{background-color:#e6f7ff;}
.SELECTED.-hx-popmenu-item:hover{background-color:#b8d0ff;color:#ffffff;}
.-hx-popmenu-head .-hx-popmenu-item:hover{background-color:inherit;color:inherit;}
.-hx-popmenu-item-text{font-size:12px;height:30px;line-height:30px;margin:0px 10px 0px 4px;min-width:10px;width:100%;}
.-mp-menu-window-title-bar{background-color:#c0c6cd;border-color:#ffffff;border-radius:0px 6px 0px 0px;border-style:solid;border-width:0px 0px 1px 0px;box-sizing:content-box;height:32px;width:100%;}
.-mp-window{box-sizing:border-box;margin:30px;position:relative;}
.-mp-dialog{margin:0px;position:fixed;z-index:10;opacity:1;}
.-mp-window-body-mini{background-color:#c0c6cd;background-position:center center;background-repeat:no-repeat;border-color:#b6bec5;border-radius:0px 0px 3px 3px;border-style:solid;border-width:0px 1px 1px 1px;box-shadow:rgba(0, 0, 0, 0.4) 0px 0px 40px 3px;box-sizing:border-box;height:100%;margin:24px 0px 0px 0px;min-height:40px;min-width:40px;width:100%;}
.-mp-window-whead-mini{background-color:transparent;border-collapse:collapse;font-size:1px;height:26px;position:absolute;width:100%;}
.-mp-wfold-fg{background-image:url(https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/chrome/window-fold-fg.svg?Signature=j3vZ5nT%2BFI06%2BqSpwp3Sfmzx3NU%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA);background-position:center center;background-repeat:no-repeat;background-size:contain;height:40px;left:-2px;position:absolute;top:0px;width:44px;}
.-mp-window-title-bar-mini{background-color:#ffffff;border-color:#ffffff;border-radius:0px 4px 0px 0px;border-style:solid;border-width:0px;box-sizing:border-box;height:20px;width:100%;}
.-mp-dialog-title-bar{background-color:#f4f4f4;border-color:#b6bec5;border-radius:0px 3px 0px 0px;border-style:solid;border-width:1px 1px 0px 0px;box-sizing:border-box;height:30px;width:100%;}
.-mp-window-close-btn{background-image:url(https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/chrome/times-oslo.svg?Signature=VeeWIwW1eahK7qlWGzB5%2FizJDCE%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA);background-position:center center;background-repeat:no-repeat;background-size:12px 12px;float:right;height:32px;position:relative;width:30px;}
.-mp-window-head{box-sizing:border-box;height:32px;padding:0px 0px 0px 40px;width:100%;}
.-mp-window .-mp-window-head{margin:-36px 0px 0px 0px;}
.-mp-window-head-mini{box-sizing:border-box;height:24px;padding:0px 0px 0px 30px;width:100%;}
.-mp-window .-mp-window-head-mini{margin:-24px 0px 0px 0px;}
.-mp-window-head-message{box-sizing:border-box;color:#3497db;font-size:12px;line-height:32px;margin:0px 0px 0px 8px;position:absolute;text-align:left;}
.-mp-window-head-message-mini{box-sizing:border-box;color:#3497db;font-size:10px;height:24px;line-height:24px;position:absolute;text-align:left;}
.-mp-window-contents{border-radius:6px 0px 0px 0px;box-sizing:border-box;font-family:Open-Sans, sans-serif;font-size:16px;height:100%;overflow-x:hidden;overflow-y:hidden;position:relative;width:100%;}
.-mp-window .-mp-window-contents{height:calc(100% + 4px);}
.-mp-white-text{color:#ffffff;}
.-mp-blue-text{color:#3497db;}
.-mp-pad-10{padding:10px 10px 0px 10px;}
.-mp-pad-15{padding:15px 15px 0px 15px;}
.-mp-window-contents-mini{box-sizing:border-box;float:none;font-family:Open-Sans, sans-serif;font-size:14px;overflow-x:visible;overflow-y:visible;position:relative;}
.-mp-window .-mp-window-contents-mini{}
.-mp-window-blocker {background-color:#80a0c0;height:100%;left:0px;opacity:0.7;filter:alpha(opacity=10);position:fixed;top:0px;width:100%;}
.-mp-dialog-btns{float:right;height:44px;margin:6px;}
.-mp-scroll-above{box-shadow:0 2px 3px rgba(0, 0, 0, 0.4);z-index:1000;}
.-mp-scroll-beneath{box-shadow:0 -2px 3px rgba(0, 0, 0, 0.4);z-index:100;}
.-mp-window-wlcell{background-color:transparent;border-color:#ff3808;border-style:solid;border-width:0px;height:40px;position:relative;vertical-align:top;width:38px;}
.-mp-window-wrcell{background-color:#ff3184;border-color:initial;border-radius:0px 6px 0px 0px;border-style:initial;border-width:initial;box-sizing:border-box;font-size:2px;height:auto;overflow-x:hidden;overflow-y:hidden;padding:0px;position:relative;vertical-align:top;}
.-mp-window-wrcell--alert{background-color:#ffffff;}
.-mp-window-wrcell--menu{background-color:#c0c6cd;}
.-mp-window-whead{background-color:transparent;border-collapse:collapse;font-size:1px;height:auto;position:absolute;width:100%;}
.-mp-window-wrow{background-color:transparent;height:auto;width:100%;}
.-mp-wfold{background-image:url(https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/chrome/mp-window.svg?Signature=sA01bjPRVBW15fOwuGrA%2BSRmeug%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA);background-position:center center;background-repeat:no-repeat;background-size:contain;height:40px;left:0px;position:absolute;top:0px;width:40px;}
.-mp-wfold--alert{background-image:url(https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/chrome/mp-alert.svg?Signature=WFcFz2WMDJU52xyurJoORojP2Eo%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA);}
.-mp-wfold--info{background-image:url(https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/chrome/mp-info-window.svg?Signature=wr98Fb9UTJdPhkTKYH73UMQ6Xx8%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA);}
.-mp-wfold--menu{background-image:url(https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/chrome/mp-menu-window.svg?Signature=zJqUSZTzgd8qMtnj03wl9f4IcC0%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA);}
.-mp-wfold--menu-dark{background-image:url(https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/chrome/mp-menu-window-dark.svg?Signature=5Vyqb%2B61RF8k4RqgYQDpuj6n5qw%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA);}
.-mp-window-body{background-color:#c0c6cd;border-radius:0px 0px 6px 6px;box-shadow:rgba(0, 0, 0, 0.4) 0px 0px 40px 3px;box-sizing:border-box;height:calc(100% - 36px);margin:36px 0px 0px 0px;min-height:40px;min-width:40px;width:100%;}
.-mp-window-body--alert{background-color:#ffffff;}
.-mp-window-body--info{background-color:#ffffff;}
.-mp-window-body--menu{background-color:#c0c6cd;}
.-mp-window-body--menu-dark{background-color:#808892;}
.-mp-window-title-bar{background-color:#ffffff;border-color:#c0c6cd;border-radius:initial;border-style:solid;border-width:0px 0px 8px 0px;box-sizing:content-box;height:32px;position:absolute;width:100%;}
.-mp-window-title-bar--alert{border-width:0px 0px 1px 0px;}
.-mp-window-title-bar--menu{background-color:#c0c6cd;border-color:#ffffff;border-width:0px 0px 1px 0px;}
.-mp-window-title-bar--menu-dark{background-color:#c0c6cd;border-color:#808892;}
.-mp-window-title-bar--info{background-color:#c0c6cd;border-color:#ffffff;}
.-mp-wfold-mini{background-image:url(https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/chrome/mp-window.svg?Signature=sA01bjPRVBW15fOwuGrA%2BSRmeug%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA);background-position:center center;background-repeat:no-repeat;background-size:contain;height:30px;left:0px;position:absolute;top:0px;width:30px;}
.-mp-window-wlcell-mini{background-color:transparent;height:30px;position:relative;width:28px;}
.-mp-window-title-bar--mini2{height:24px;}
.-mp-wfold-mini-fg{background-image:url(https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/chrome/window-fold-fg.svg?Signature=j3vZ5nT%2BFI06%2BqSpwp3Sfmzx3NU%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA);background-position:center center;background-repeat:no-repeat;background-size:contain;height:30px;left:0px;position:absolute;top:0px;width:30px;}
.-hx-button{border-radius:300px;border-style:solid;border-width:1px;box-shadow:0 3px 6px rgba(0, 0, 0, 0.4);box-sizing:border-box;color:#ffffff;cursor:pointer;height:32px;padding:0px 0px 3px 0px;position:relative;width:auto;}
.PRESSED.-hx-button{border-style:solid;box-shadow:none;top:2px;}
.PRESSED{top:2px;}
.PRESSED.-hx-button-mini{border-style:solid;box-shadow:none;top:1px;}
.-hx-button-label{font-size:12px;height:100%;line-height:30px;text-align:center;text-transform:capitalize;width:100%;}
.-hx-button-action{background-color:#3497db;border-color:#3497db;}
.-hx-button-danger{background-color:#dd362f;border-color:#dd362f;}
.-hx-button-warning{background-color:#f89406;border-color:#f89406;}
.-hx-button-success{background-color:#51a351;border-color:#51a351;}
.-hx-button-info{background-color:#00aaaa;border-color:#00aaaa;}
.-hx-button-inverse{background-color:#444444;border-color:#444444;}
.-hx-check-box{background-color:#f4f4f4;border-color:#9aabb3;border-radius:2px;border-style:solid;border-width:1px;cursor:pointer;height:20px;position:relative;width:20px;}
.-hx-check-box-label{font-size:16px;height:100%;line-height:24px;margin:0px 0px 0px 4px;text-align:center;text-transform:capitalize;width:auto;}
.-hx-check-box [name=inner]{height:100%;width:100%;}
.PRESSED.-hx-check-box{background-color:#ccdde5;}
.CHECKED.-hx-check-box [name=inner]{background-image:url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%3Csvg%20width%3D%22100%25%22%20height%3D%22100%25%22%20viewBox%3D%220%200%2022%2018%22%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20xml%3Aspace%3D%22preserve%22%20style%3D%22fill-rule%3Aevenodd%3Bclip-rule%3Aevenodd%3Bstroke-linejoin%3Around%3Bstroke-miterlimit%3A1.41421%3B%22%3E%3Cpath%20d%3D%22M19.678%2C2.885c0.046%2C0.331%20-0.03%2C0.629%20-0.23%2C0.894l-7.737%2C10.267l-1.453%2C1.929c-0.199%2C0.264%20-0.465%2C0.42%20-0.796%2C0.467c-0.332%2C0.046%20-0.63%2C-0.03%20-0.895%2C-0.23l-1.929%2C-1.453l-5.133%2C-3.868c-0.265%2C-0.2%20-0.42%2C-0.465%20-0.467%2C-0.797c-0.047%2C-0.331%200.03%2C-0.63%200.229%2C-0.894l1.454%2C-1.929c0.199%2C-0.265%200.465%2C-0.42%200.796%2C-0.467c0.332%2C-0.047%200.63%2C0.03%200.895%2C0.229l4.171%2C3.155l7.008%2C-9.316c0.199%2C-0.264%200.465%2C-0.42%200.796%2C-0.467c0.332%2C-0.046%200.63%2C0.03%200.895%2C0.23l1.929%2C1.453c0.264%2C0.2%200.42%2C0.465%200.467%2C0.797Z%22%20style%3D%22fill%3A%233497db%3Bfill-rule%3Anonzero%3B%22/%3E%3C/svg%3E');background-position:center center;background-repeat:no-repeat;background-size:18px 18px;}
@font-face{font-family:Open-Sans;src:url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Light-webfont.eot?Signature=HYbjlSDrwsRnFabXH6qbamhZuYU%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA");src:url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Light-webfont.eot?#iefix&Signature=HYbjlSDrwsRnFabXH6qbamhZuYU%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA") format("embedded-opentype"),url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Light-webfont.woff?Signature=A3xrfnj1r8qXojglHV5QjspTDeY%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA") format("woff"),url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Light-webfont.ttf?Signature=JTe5UcOwqPRyUe6oIToEDdt4VMY%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA") format("truetype"),url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Light-webfont.svg?Signature=BY4C1%2FeGPFJLCJVpmR8XFqILd4o%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA") format("svg");font-weight:200;}
@font-face{font-family:'Open-Sans';src:url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Regular-webfont.eot?Signature=lOdJY61fdK8OMni3vAGxVt4vDWA%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA");src:url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Regular-webfont.eot?#iefix&Signature=lOdJY61fdK8OMni3vAGxVt4vDWA%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA") format("embedded-opentype"),url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Regular-webfont.woff?Signature=RVvSNG%2BzZ6WGppfK1SRcSRYvtxI%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA") format("woff"),url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Regular-webfont.ttf?Signature=40h59kB34IuRjDM%2Fm6MsKPy%2FZJI%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA") format("truetype"),url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Regular-webfont.svg?Signature=79%2FpcWLJJ6TmYinphjyV0203TBI%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA") format("svg");font-weight:400;}
@font-face{font-family:'Open-Sans';src:url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Semibold-webfont.eot?Signature=jRW7JauzstN4i5VhbQmwR2822b4%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA");src:url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Semibold-webfont.eot?#iefix&Signature=jRW7JauzstN4i5VhbQmwR2822b4%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA") format("embedded-opentype"),url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Semibold-webfont.woff?Signature=ZVaHDZo7MjmUaLPEDRiOzsal630%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA") format("woff"),url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Semibold-webfont.ttf?Signature=qnh8VAiI9GMHMi2FlBXRA9BbA94%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA") format("truetype"),url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Semibold-webfont.svg?Signature=a1Zph7R9pDHXcA8P31GFpWLib70%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA") format("svg");font-weight:500;}
@font-face{font-family:'Open-Sans';src:url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Bold-webfont.eot?Signature=wTB%2F%2BloZO%2Ficf%2BXa4FWLtyP%2FrzM%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA");src:url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Bold-webfont.eot?#iefix&Signature=wTB%2F%2BloZO%2Ficf%2BXa4FWLtyP%2FrzM%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA") format("embedded-opentype"),url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Bold-webfont.woff?Signature=Xgz%2BLyJaZUcN29Sn7M303IDUfyg%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA") format("woff"),url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Bold-webfont.ttf?Signature=s4nOJc5sL644DhfpCWYtt7eZhY0%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA") format("truetype"),url("https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/opensans/OpenSans-Bold-webfont.svg?Signature=Qu9IKPUI%2FPUa7ag4rNEswl%2BPdBo%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA") format("svg");font-weight:700;}


.-hx-check-box > input {position:absolute;top:-3px;left:-4px;width:100%;height:100%;opacity:0.1;}
.hx-table{background-color:#f6f6f6;border-color:#888888;border-style:solid;border-width:0px;color:#444444;float:right;font-size:14px;height:871px;margin:0px 0px 0px 4px;padding:0px 12px 0px 0px;width:820px;}
.hx-table-sheet{background-color:#f0f0f0;max-height:840px;overflow-y:scroll;position:relative;width:calc(100% + 12px);}
td,th{border-color:#888888;border-style:solid;border-width:0px;box-sizing:border-box;height:24px;line-height:24px;overflow-x:hidden;overflow-y:hidden;padding:2px 4px 2px 2px;vertical-align:middle;}
td {text-align:left}
.table-head{border-color:#cccccc;border-style:solid;border-width:0px 0px 3px 0px;width:100%;}
.table-head div{border-color:#888888;border-style:solid;border-width:0px;box-sizing:border-box;height:28px;line-height:24px;overflow-x:hidden;overflow-y:hidden;padding:4px 4px 2px 4px;vertical-align:middle;}
.scroll-bar-handle{background-color:#b8b8b8;border-color:#aaaaaa;border-radius:20px;border-style:solid;border-width:1px;box-sizing:border-box;height:24px;left:0px;max-width:100%;min-height:24px;position:absolute;width:100%;}
tr{border-color:#888888;border-style:solid;border-width:0px;}
tr.REPEAT-EVEN{background-color:#e0e0e0;}
.col_odd{background-color:#e4e4e4;}
tr.REPEAT-EVEN .col_odd{background-color:#d8d8d8;}
.table-head div .col_odd{background-color:#e8e8e8;}
.words{box-sizing:border-box;font-size:16px;padding:0px 0px 0px 8px;width:200px;}
.frequency_counts{overflow-x:hidden;overflow-y:hidden;text-align:right;width:100px;}
.frequency_per_million{overflow-x:hidden;overflow-y:hidden;text-align:right;width:70px;}
.log_frequency_per_million{overflow-x:hidden;overflow-y:hidden;text-align:right;width:110px;}
.zipf{display:none;overflow-x:hidden;overflow-y:hidden;text-align:right;width:50px;}
.contextual_diversity{overflow-x:hidden;overflow-y:hidden;text-align:right;width:40px;}
.old20{overflow-x:hidden;overflow-y:hidden;text-align:right;width:40px;}
.dispersion{overflow-x:hidden;overflow-y:hidden;text-align:right;width:80px;}
.u{overflow-x:hidden;overflow-y:hidden;text-align:right;width:80px;}
.sfi{overflow-x:hidden;overflow-y:hidden;text-align:right;width:60px;}
.n_letters{overflow-x:hidden;overflow-y:hidden;text-align:right;width:80px;}

/* lemmas */
.lemma{font-size:16px;padding:2px 0px 2px 8px;width:220px;}
.frequency{font-size:14px;padding:2px 0px 2px 8px;width:120px;}
.f_m{font-size:14px;padding:2px 0px 2px 8px;width:150px;}
.log_f_m{font-size:14px;padding:0px 0px 0px 8px;width:150px;}

h1,h2,h3{color:#666666;}
.filter_box{background-color:#eeeeee;border-color:#cccccc;border-radius:4px;border-style:solid;border-width:1px;box-sizing:border-box;clear:both;height:auto;margin:0px 0px 2px 0px;padding:0px;width:100%;}
.all-only{}
.page-all-years .all-only{background-color:#44dd33;display:block;}
.page-all-years{color:#ff6252;font-style:italic;font-weight:bold;}
.condition-pop{clear:both;margin:0px 0px 3px 33px;width:80px;}
.-hx-text-entry{border-color:#414c58;border-radius:2px;border-style:solid;border-width:1px;box-sizing:border-box;color:#282828;font-size:12px;height:32px;line-height:32px;padding:0px 0px 0px 6px;}
.value-entry{margin:0px 0px 0px 6px;width:136px;}
.active-check{float:left;margin:3px 3px 0px 3px;position:relative;}
.col-name-lbl{cursor:pointer;font-size:11px;height:22px;line-height:22px;margin:0px 0px 0px 4px;padding:0px;text-align:left;width:210px;}
.controls-label{border-color:#888888;border-style:solid;border-width:0px;box-sizing:border-box;color:#666666;font-size:14px;font-weight:bold;height:22px;line-height:22px;margin:5px 0px 0px 0px;padding:0px 5px 0px 5px;width:100%;}
.scroll-bar{background-color:#eeeeee;border-color:#cccccc;border-style:solid;border-width:3px 0px 0px 1px;box-sizing:content-box;height:100%;position:absolute;right:-12px;top:-3px;width:12px;}
.menu-btn{background-color:#ffffff;border-color:#888888;border-style:solid;border-width:1px 0px 1px 1px;box-sizing:border-box;cursor:pointer;font-size:14px;height:40px;line-height:2;text-align:center;width:16.6%;}
.menu-btn-label{color:#444444;font-family:sans-serif;height:100%;line-height:36px;text-align:center;width:100%;}
.menu-btn.DISABLED{background-color:#bebebe;line-height:1.8;opacity:1.000;filter:alpha(opacity=100);text-shadow:0 0px 2px rgba(1,1,1, 0.25);}
.menu-btn.SELECTED{background-color:#bebebe;font-weight:bold;line-height:1.8;opacity:1.000;filter:alpha(opacity=100);text-shadow:0 0px 2px rgba(1,1,1, 0.25);}
textarea{border-color:#888888;border-width:1px 0px 1px 0px;box-sizing:border-box;font-size:16px;line-height:1.4;max-width:100%;overflow-x:hidden;overflow-y:scroll;padding:5px 20px 5px 20px;width:100%;}

h1,h2,h3{color:#666666;}
.flag{background-image:url(https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/GB-GR-Flag.png?Signature=DJeXbOZiYMrJRti4DbuFMccN1IQ%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA&versionId=FC01c885YZv2c4MEAJVz6TrES2Y7Nk4D&x-amz-meta-mp_modified=Thu%2C%2011%20Oct%202018%2014%3A37%3A04%20GMT&vers=20181011143704);background-position:center top;background-repeat:no-repeat;background-size:cover;border-radius:6px;height:25px;opacity:0.200;filter:alpha(opacity=20);position:relative;width:38px;}
.flag.SELECTED{opacity:1.000;filter:alpha(opacity=100);}
span.lited{color:#ff0c04;font-weight:bold;}
.lang-en{}
.lang-el{}
.EN-ACTIVE{}
.EL-ACTIVE{}
.EN-ACTIVE .lang-en{}
.EN-ACTIVE .lang-el{display:none;}
.EL-ACTIVE .lang-el{}
.EL-ACTIVE .lang-en{display:none;}
.EL-ACTIVE [name=EL]{opacity:1.000;filter:alpha(opacity=100);}
.EN-ACTIVE [name=EN]{opacity:1.000;filter:alpha(opacity=100);}

.ghostRowPreventsAutoScroll {
  position: absolute;
  background: transparent;
  width: 10%;
  opacity: 0.3;
  right: 18px;
}